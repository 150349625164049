import React from "react";
import {
  HeroSection,
  Page,
  Seo,
} from "gatsby-theme-portfolio-minimal";

export default function WarthogPage() {
  return (
    <>
      <Seo title="Warthog" />
      <img src="/warthog.png"></img>
    </>
  );
}
